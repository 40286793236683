import type { ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon, ContextualMissingChip, OpenIdSubjectKeyChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
export const OpenIdSubjectTypedMultiSelect = MultiSelect<ReferenceDataItem>();
export const AvailableDeploymentSubjectKeys: ReferenceDataItem[] = [
    { Id: "space", Name: "Space" },
    { Id: "environment", Name: "Environment" },
    { Id: "project", Name: "Project" },
    { Id: "tenant", Name: "Tenant" },
    { Id: "account", Name: "Account" },
    { Id: "type", Name: "Type" },
];
export const AvailableRunbookSubjectKeys: ReferenceDataItem[] = [
    { Id: "space", Name: "Space" },
    { Id: "environment", Name: "Environment" },
    { Id: "project", Name: "Project" },
    { Id: "tenant", Name: "Tenant" },
    { Id: "runbook", Name: "Runbook" },
    { Id: "account", Name: "Account" },
    { Id: "type", Name: "Type" },
];
function calculateAvailableExecutionSubjectKeys(): ReferenceDataItem[] {
    const allKeys = AvailableRunbookSubjectKeys.concat(AvailableDeploymentSubjectKeys);
    const map = new Map();
    for (const key of allKeys) {
        map.set(key.Id, key);
    }
    return Array.from(map.values());
}
export const AvailableExecutionSubjectKeys = calculateAvailableExecutionSubjectKeys();
export const DefaultDeploymentSubjectKeys: string[] = ["space", "project", "tenant", "environment"];
export const SubjectKeyOrder: string[] = ["space", "project", "runbook", "tenant", "environment", "target", "account", "type"];
export const AvailableHealthCheckSubjectKeys: ReferenceDataItem[] = [
    { Id: "space", Name: "Space" },
    { Id: "account", Name: "Account" },
    { Id: "target", Name: "Target" },
    { Id: "type", Name: "Type" },
];
export const DefaultHealthCheckSubjectKeys: string[] = ["space", "target"];
export const AvailableAccountTestSubjectKeys: ReferenceDataItem[] = [
    { Id: "space", Name: "Space" },
    { Id: "account", Name: "Account" },
    { Id: "type", Name: "Type" },
];
export const DefaultAccountTestSubjectKeys: string[] = ["space", "account"];
interface OpenIdSubjectMultiSelectProps extends FormFieldProps<string[]> {
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
    accessibleName?: string;
    onRemove?: (id: string) => void;
    fallbackLabel?: string;
    fallbackDescription?: string;
    lookupCollection?: ReferenceDataItem[];
    subjectKeys: ReferenceDataItem[];
}
export const OpenIdSubjectMultiSelect: React.FC<OpenIdSubjectMultiSelectProps> = (props) => {
    const chipRenderer = (r: ReferenceDataItem | SelectItem, onRequestDelete: () => void) => (<Lookup lookupCollection={props.subjectKeys} lookupId={r.Id} getIdFromElement={(element: ReferenceDataItem) => element.Id} render={(item: ReferenceDataItem) => <OpenIdSubjectKeyChip subjectName={item.Name} deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete}/>} renderFallback={<ContextualMissingChip lookupKey={r.Id} type={ChipIcon.Environment} text={props.fallbackLabel} description={props.fallbackDescription} onRequestDelete={onRequestDelete}/>}/>);
    return <OpenIdSubjectTypedMultiSelect fieldName="keys" renderChip={chipRenderer} accessibleName={"Subject Keys"} sortItems={false} items={props.subjectKeys ?? []} {...props}/>;
};
OpenIdSubjectMultiSelect.displayName = "OpenIdSubjectMultiSelect"
